<template>
  <section
    class="background mt-14 lg:mt-32"
  >
    <div class=" container">
      <MediaView
        v-if="media"
        :link="true"
        :type="titleEnabled ? title : undefined"
        :value="media"
      />
    </div>
  </section>
</template>

<script lang="ts" setup>
import {useMediaQuery} from '@graphql/graphql';
import type {SectionProps} from '~/types/section';

const props = defineProps<SectionProps>();

const {result} = useMediaQuery({
  limit: 1,
}, computed(() => ({
  enabled: !props.media,
})));

const media = computed(() => props.media ?? result.value?.media?.data?.[0]);
</script>

<style scoped>
.background {
  background: linear-gradient(to bottom, transparent, transparent 55%, theme("colors.black.900") 55%);
}
</style>
